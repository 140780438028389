<template>
  <div>
    <!-- page title ################### -->
    <div class="big-head">
      <v-layout align-center>
        <span>#{{$route.params.order}}</span>
        <v-flex grow>
          <h1>
            {{$route.params.name}}
            <em></em>
          </h1>
        </v-flex>
        <v-flex blue-select shrink>
          <!-- meio de pagamento ########### -->
          <!-- <InputSelect
            ref="companyGroupListInput"
            textLabel
            v-model="paymentMethod"
            :items="items"
            valueAttribute="id"
            textAttribute="name"
            placeholder="Meio de Pagto"
          ></InputSelect>-->
        </v-flex>
                <v-flex shrink>
          <InputSelect
            class="ml-3"
            :items="[
              { id: 10, name: 10 },
              { id: 20, name: 20 },
              { id: 50, name: 50 },
              { id: 100, name: 100 },
            ]"
            valueAttribute="id"
            textAttribute="name"
            v-model="pageSize"
          />
        </v-flex>
      </v-layout>
    </div>
    <ListDonationsCashFlow
      :enableCheckbox="true"
      :enableDownloadExcelCsv="entity.type == entityTypeEnum.AUC"
      :enableModalLevel2="entity.type == entityTypeEnum.AUC"
      :enableOpenModalUser="false"
      :level="3"
      :paymentMethod="paymentMethod"
      :pageSize="pageSize"
      :paginate="true"
      :statusConciliation="statusConciliation"
      :statusTransferResource="statusTransferResource.PendentAcceptInstitution"
      :pageNumber="cashFlowPagesEnum.PendentAcceptToInstitution"
      :enableConcilied="false"
      :selectOnlyOne="true"
    ></ListDonationsCashFlow>
  </div>
</template>
<script type="plain/text">
import ListDonationsCashFlow from "@/views/app/resourceFlow/ListDonationsCashFlow.vue";
import { EntityType } from "@/scripts/models/enums/entityType.enum.js";
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";
import { CashFlowPages } from "@/scripts/models/enums/cashFlowPages.enum";
import { StatusTransferResource } from "@/scripts/models/enums/statusTransferResource.enum";
import DonationConciliationStatus from "@/scripts/models/enums/donationConciliationStatus.enum";

export default {
  components: {
    ListDonationsCashFlow
  },
  props: {
    pageOptions: null,
    entity: null
  },
  data() {
    return {
      items: [
        { id: PaymentMethod.PayrollDeduction, name: "Débito em Folha" },
        { id: PaymentMethod.Ticket, name: "Boleto" },
        { id: PaymentMethod.CreditCard, name: "Cartão de Crédito" }
      ],
      paymentMethod: 0,
      pageSize: 10,
      cashFlowPagesEnum: "",
      entityTypeEnum: null,
      statusConciliation: DonationConciliationStatus.ConciliedByAuc
    };
  },
  created() {
    this.cashFlowPagesEnum = CashFlowPages;
    this.statusTransferResource = StatusTransferResource;
    this.entityTypeEnum = EntityType;
    this.$emit("pageActive", CashFlowPages.PendentAcceptToInstitution);
  }
};
</script>
